<template>
  <b-container class="pt-3">
    <PlayersFilters @filter="filterPlayers" />
    <b-row class="mt-4" v-if="!playersError">
      <b-col>
        <h-card>
          <b-row v-if="playersLoaded && playersResult.length === 0">
            <b-col class="text-center">{{ $t('player.noResult') }}</b-col>
          </b-row>

          <template v-for="player in playersResult">
            <PlayersItem :player="player" :key="player.idPlayer" />
          </template>
          <template v-if="(!playersLoaded || playerNextLoaded) && !endOfList">
            <PlayerItemSkeleton
              class="d-none d-md-block"
              v-for="i in 10"
              :key="i"
            />
            <PlayerItemSkeleton class="d-block d-md-none" />
          </template>
        </h-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col> <HServerError /> </b-col>
    </b-row>
    <HScrollTopBtn :scrollTopBtn="scrollTopBtn" />
  </b-container>
</template>

<script>
import { mapState } from 'vuex'

import PlayersFilters from '@/components/PlayersFilters'
import PlayersItem from '@/components/PlayersItem'
import PlayerItemSkeleton from '@/components/skeleton/PlayerItemSkeleton'
export default {
  data() {
    return {
      scrollTopBtn: false,
      busy: false,
      endOfList: false,
    }
  },
  components: {
    PlayersFilters,
    PlayersItem,
    PlayerItemSkeleton,
  },
  computed: {
    ...mapState({
      playersFilters: (state) => state.player.playersFilters,
      playersResult: (state) => state.player.players,
      totalResults: (state) => state.player.totalResults,
      playersLoaded: (state) => state.player.playersLoaded,
      playerNextLoaded: (state) => state.player.playersLoaded,
      playersError: (state) => state.player.playersError,
    }),
  },
  created() {
    this.$store.commit('player/SERVER_ERROR', false)
    window.scrollTo(0, 0)
    this.filterPlayers()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    checkEndList() {
      if (this.playersResult.length === this.totalResults) this.endOfList = true
      else this.endOfList = false
    },
    filterPlayers() {
      this.endOfList = false
      this.$store.dispatch('player/_incrementPage', 1)
      this.$store.dispatch('player/_clearPlayers')
      this.getPlayers(this.playersFilters)
    },
    async getPlayers(filters) {
      try {
        await this.$store.dispatch('player/_getPlayers', filters)
        this.checkEndList()
        this.busy = false
        if (this.playersError) this.$store.commit('player/SERVER_ERROR', false)
      } catch (e) {
        if (e.response && e.response.status !== 401) {
          this.$toast.error(this.$t('errors.generic'))
        }
        this.endOfList = true
        this.$store.commit('player/SERVER_ERROR', true)
      } finally {
        this.busy = false
      }
    },
    handleScroll() {
      if (document.documentElement.scrollTop > 500) this.scrollTopBtn = true
      else this.scrollTopBtn = false
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 600
      if (
        bottomOfWindow &&
        !this.busy &&
        this.playersLoaded &&
        !this.endOfList
      ) {
        this.busy = true
        this.$store.dispatch('player/_incrementPage')
        this.playersResult.length
        this.getPlayers(this.playersFilters)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
